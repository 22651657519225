import React, { useState, useRef } from 'react'
import "./Login.scss"
import logo from '../../images/logo.png'
import { useNavigate } from 'react-router-dom'
import loginPic from '../../images/our_vision2.jpg'
import { AdminLoginService } from '../../services/admin.service'
import img1 from '../../images/show-Icon.png'
import img2 from '../../images/hide-image.png'

function Login() {

    const inputRef = useRef(null)
    const navigate = useNavigate()

    // const [isLogin, setIsLogin] = useState(true)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [show, setShow] = useState(false)
    const handleClick = () => {
        setShow(!show);
    }


    const handleOnClick = async (e) => {
        e.preventDefault()

        try {
            const data = await AdminLoginService({ email, password })
            console.log(data)
            if (data.error) {
                const errMassege = data.error.response.data.data.message
                if(errMassege.include("user")){
                    console.log("email");
                }
                console.log(data.error.response.data.data.message)
                alert(data.error?.response?.data?.data?.message);
            } else {
                const objString = JSON.stringify(data.data);
                localStorage.setItem("user", objString)
                navigate("/admin")
            }
        } catch (error) {

            console.log(error);
        }
    }
    return (
        <>
            <div>
                <div className='mai_div'>
                    <div className='login-pic' style={{ marginLeft: '2rem' }}>
                        <img src={loginPic} />
                    </div>
                    <div className='login-form'>
                        <div>
                            <img src={logo} alt='' width={"100px"} onClick={() => {
                                navigate("/")
                            }} />
                            <h3 className='text_area'>Skyline Construction PVT LTD</h3>
                        </div>
                        <div className='inputs'>
                            <div className='input-items' >
                                <input type='email' name='email' value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder='Email Id'
                                    inputRef
                                />
                            </div>
                            <div className='input-items'>
                                <input type={show ? 'text' : 'password'} name='password' value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder='Password'
                                />
                                {
                                    show ? (
                                        <img src={img1} alt='' className='icon' onClick={handleClick} />
                                    ) : (
                                        <img src={img2} alt='' className='icon' onClick={handleClick} />
                                    )
                                }
                            </div>
                        </div>
                        <div className='sing_btn'>
                            <button onClick={handleOnClick}>Login</button>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Login
