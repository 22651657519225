import React from 'react'

function Info() {
    return (
        <>
            
        </>
    )
}

export default Info