import React from 'react'
import { useEffect } from 'react'
import style from './About.module.scss'
// import sideimg from '../../images/company-pic.jpg'
// import img3 from '../../images/construction-background.jpg'
import imgAbout from '../../images/about.jpeg'
// import asideImg from '../../images/about-aside-image2.jpg'
import asideImg2 from '../../images/aside-image2.jpg'
import asideImg3 from '../../images/about-aside-image3.svg'
import ourVision from '../../images/our_vision3.png'
import ourMission from '../../images/our_mission.png'

// import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';


function About() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      <div className={style.mainAbout1}>
        <div className={style.mainAbout2}>
          {/* <div className={style.heading}>
            <h1 className={style.textFromRight}><i>Skyline construction &</i></h1>
            <h1 className={style.textFromRight}><i></i>Engineers pvt ltd</h1>
          </div> */}
          <img src={imgAbout} alt={'about'} />
        </div>
        <div data-aos="zoom-in" data-aos-duration='1300'>
          <div className={style.mainAbout3}>
            <div className={style.paraImg}>
              <img src={asideImg3} alt={'company pic'} />

              <div className={style.para}>
                <h3>Who we are</h3>
                <br />
                <p>Skyline Construction was established as a proprietorship concern namely "IRAJ I. SIDDIQUI" in 2014 by "IRAJ I. SIDDIQUI." The firm was engaged in carrying out commercial and residential projects (government and private) in the earlier years. The firm was also started working as a supplier of Ready Mix Concrete in 2017. The company is known for its highest quality and attentively towards their clients. The company has executed sizable buildings, bridges, and roads in the state of Maharashtra strictly with the government quality norms and specifications.</p>

              </div>
            </div>

          </div>
        </div>

      </div>
      <div className={style.aboutSubContent1}>
        <div className={style.aboutSubContent2}>
          <img src={asideImg2} alt={'aside'} />
          <div className={style.aboutSubContent3}>
            <h3>We're knowledgeable about
              build your dream project...</h3><br />
            <p>
              The unwavering dedication and commitment of Skyline Construction, a renowned construction company, extends far beyond exceptional workmanship and timely project completion. Our primary goal is to ensure complete client satisfaction at every phase of the project. We have cultivated a team of highly skilled professionals who possess the expertise and precision necessary to deliver outstanding results. With construction as our driving force, Skyline Construction aims to build your dream project with unmatched quality and efficiency.</p>
          </div>
        </div>
      </div>
      <div className={style.surveyer1}>
        <div className={style.surveyer2}>
          <div className={style.surveyerText}>
            {/* <h4>Great<i> Experience </i>for</h4> */}
            <h2>Skyline
              is a fastest growing company in the field of civil Engineering, in
              Roadways, Railways, Irrigation and heavy Earth moving equipment
              Machinery, having registered office at Mumbai and Parbhani.
            </h2>
          </div>
        </div>
      </div>

      <div className={style.vision} >
        <div data-aos="fade-left" data-aos-duration='1500'>
          <img src={ourVision} alt={'our-vision'} />
        </div>
        <div data-aos="fade-right" data-aos-duration='1500'>
          <div className={style.visionText}>
            <h2>Our Vision</h2>
            <p>“As a construction company, our vision is to be a leading provider of high-quality, sustainable, and innovative construction solutions that meet the needs of our clients while also promoting the greater good of our community and the environment. We strive to exceed our clients' expectations by delivering exceptional value and service through our team's expertise, dedication, and passion for excellence. We envision a future where our projects not only meet the highest standards of quality and safety but also make a positive impact on the communities in which they are built. Our commitment to sustainability and environmentally responsible practices is a cornerstone of our vision, as we believe that the construction industry can and should play a significant role in building a more sustainable and equitable future for all.”</p><br />
          </div>
        </div>
      </div>

      <div className={style.mission}>
        <div data-aos="fade-right" data-aos-duration='1500'>
          <img src={ourMission} alt={'our-mission'} />
        </div>
        <div data-aos="fade-left" data-aos-duration='1500'>
          <div className={style.missionText}>
            <h2>Our Mission</h2>
            <p>Our mission as a construction company is to provide our clients with reliable, efficient, and cost-effective construction solutions that exceed their expectations. We are committed to delivering projects on time and within budget, while also maintaining the highest standards of quality and safety. We strive to build strong relationships with our clients, employees, subcontractors, and suppliers, based on honesty, integrity, and mutual respect. We aim to be a company that our clients can trust to deliver exceptional results, time and time again. In addition, we are committed to promoting sustainability in everything we do, from our building practices to our daily operations. We believe that by incorporating sustainable practices into our business model, we can help create a better future for ourselves, our clients, and the communities we serve. Overall, our mission is to be a construction company that is known for its excellence, reliability, and commitment to sustainability.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default About