
import { useEffect, useState,React,  } from "react";
import "./Completed.scss";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { getProjectByQuery } from "../../services/admin.service";

function Completed() {
  const [dataRow, setDataRow] = useState([]);
  const fetchData = async () => {
    const data = await getProjectByQuery("Ongoing");
    setDataRow(data.data.Events);

    console.log(data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <table>
        <tr>
          <th>sr no</th>
          <th>Name of work</th>
          <th>Amount of Work ( In Lacs)</th>
          <th>Work Done (In lacs)</th>
          <th>Balance work (In lacs)</th>
        </tr>
        {dataRow.map((item, index) => {
          return (
            <tr>
              <td scope="row">{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.amount}</td>
              <td>{item.balanceWork}</td>
              <td>{item.workDone}</td>
            </tr>
          );
        })}
      </table>
    </>
  );
}

export default Completed;
