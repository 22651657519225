import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { ToastContainer, toast } from "react-toastify";
import {
  CompleteEvent,
  deleteAssetsId,
  deleteEventId,
  getEventAll,
} from "../../services/admin.service";
import KeepMountedModal from "./KeepMountedModal";
import Button from "@mui/material/Button";

function Assets() {
  const deleteRow = async (data) => {
    // console.log(data)
    const res = await deleteAssetsId(data.id);
    console.log(res);
    if(res.status===201){
      toast.success('deleted susscessfully')
    }else{
      toast.error('Something went wrong')
    }
   
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "Sr no", renderCell: (params) => params._id },
    {
      field: "name",
      headerName: "Equipment",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "noUnits",
      headerName: "No of Unit",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "madeBy",
      headerName: "Kind of Make",
      flex: 1,
    },
    {
      field: "capacity",
      headerName: "Capacity",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => (
        <Button variant="contained" color="primary">
          Edit
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => deleteRow(params)}

        >
          Delete
        </Button>
      ),
    },
  ];
  const [dataRow, setDataRow] = React.useState([]);

  const fetchData = async () => {
    const data = await getEventAll();
    console.log("hello");
    setDataRow(data.data.Events);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div
        className="addCategoryDiv"
        style={{ textAlign: "right", marginTop: "1rem", marginRight: "1.7rem" }}
      >
        <div className="addCategoryButton">
          <KeepMountedModal refetch={fetchData} />
        </div>
      </div>
      <Box m="20px">
        {/* <Header title="TEAM" subtitle="Managing the Team Members" /> */}
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid
            rows={dataRow}
            columns={columns}
            getRowId={(row) => row._id}
          />
        </Box>
      </Box>
      <ToastContainer />
    </>
  );
}

export default Assets;
