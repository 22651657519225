import React from "react";
import "./OurEmployes.css";
import { useState,useEffect } from "react";
import { getEmploys, getEventAll } from "../../services/admin.service";

function OurEmployes() {
    const [dataRow, setDataRow] = useState([])

    const fetchData = async () => {
      const data = await getEmploys()
      console.log(data.data.Events, "Employes data");
    }
    useEffect(() => {
      fetchData()
    }, [])
  return (
    <>
      <div className="employes_heading">
        <h2>OUR EMPLOYEES</h2>
        <p>
          The Pillars of Our Success At Skyline Contractors, our employees are
          the driving force behind our success. As a team, we are united by a
          shared vision of excellence and a passion for construction. Here's
          what makes our employees exceptional: Skill and Expertise: Our
          employees are handpicked for their skills, knowledge, and expertise in
          their respective fields. They bring years of experience and a deep
          understanding of the industry. Dedication: We have a team of dedicated
          professionals who go above and beyond to ensure that each project is
          executed with precision and care. Team Spirit: Collaboration is
          ingrained in our culture. Our employees work together seamlessly,
          leveraging their individual strengths to achieve collective goals.
          Problem Solvers: Challenges are viewed as opportunities for growth.
          Our employees are adept at finding innovative solutions to overcome
          obstacles. Integrity: Honesty and integrity are the cornerstones of
          our team. We uphold the highest ethical standards in all our
          interactions. Continuous Learning: The construction industry is
          constantly evolving, and so do our employees. They are committed to
          continuous learning and staying updated with the latest trends and
          technologies. Safety Conscious: Our employees understand the
          importance of safety and follow best practices to ensure a secure
          working environment. Customer-Focused: We believe in putting our
          clients first. Our employees actively listen to clients' needs and
          strive to deliver solutions that exceed expectations. Together, our
          team of talented individuals drives the growth and success of Skyline Contractors.
           They are the backbone of our organization and the
          reason why we consistently deliver exceptional construction projects
          to our valued clients.
        </p>
      </div>
      <div className='d-flex' style={{overflowX:'scroll' ,padding:'0.2vh' ,fontSize:"2.4vh"}}> 
        <table className='table table-hover' >
          <tr  className="thead-dark">
            <th scope='col'>sr no</th>
            <th scope='col' >Name Employ</th>
            <th scope='col'>Qulification</th>
            <th scope='col'>Designation</th>
          </tr>

          {
            dataRow.map((item, index) => {
              return (

                <tr>
                  <td scope="row" >{index + 1}</td>
                  <td >{item.name}</td>
                  <td>{item.noUnits}</td>
                  <td>{item.madeBy}</td>
                  <td>{item.capacity}</td>
          

                </tr>
              )
            })
          }

        </table >
      </div>
    </>
  );
}

export default OurEmployes;
