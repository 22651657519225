import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import {

  deleteGalleyId,
  getAllGalery,
} from "../../services/admin.service";
import Button from "@mui/material/Button";
import "./AddGallery.css";
import MountedModal from "./MountedModal";

function AddGallery() {
  const [dataRow, setDataRow] = React.useState([]);

  const fetchData = async () => {
    const data = await getAllGalery();
    setDataRow(data.data.Events);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleDelete = async (data) => {
    const res = await deleteGalleyId(data);
    if (res.status === 201 ||res.status===200) {
      toast.success("Image Deleted Succsessfully")
    }else{
      toast.error("Somthing Went Wrong")
    }
  };
  return (
    <>
      <div
        className="addCategoryDiv"
        style={{ textAlign: "right", marginTop: "1rem", marginRight: "1.7rem" }}
      >
        <div className="addCategoryButton">
          <MountedModal refetch={fetchData} />
        </div>
      </div>
      <Box m="20px">
        <div className="image-galley">
          <ImageList sx={{ width: 600, height: 350 }}>
            {dataRow.map((item) => (
              <>
                <ImageListItem key={item.img}>
                  <img
                    src={`${item.image}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item._id}
                    loading="lazy"
                  />

                  <ImageListItemBar title={item.title} position="below" />
                  <Button
                    variant="contained"
                    color="error"
                    type="submit"
                    sx={{ margin: "2rem" }}
                    onClick={() => handleDelete(item._id)}
                  >
                    delete
                  </Button>
                </ImageListItem>
              </>
            ))}
          </ImageList>
        </div>
      </Box>
      <ToastContainer />
    </>
  );
}

export default AddGallery;
