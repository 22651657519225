import React, { useEffect, useState } from "react";
import "./Completed.scss";
import {
  getProjectByQuery,
} from "../../services/admin.service";


function Completed() {
  const [dataRow, setDataRow] = useState([]);

  const fetchData = async () => {
    const data = await getProjectByQuery("Completed");
    setDataRow(data.data.Events);

    console.log(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Skyline@786
  // sharfuddin0147@gmail.com
  return (
    <>
      <table>
        <tr>
          <th>sr no</th>
          <th>Name of work</th>
          <th>Amount of Work ( In Lacs)</th>
         
        </tr>
        {dataRow.map((item, index) => {
          return (
            <tr>
              <td scope="row">{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.amount}</td>
            </tr>
          );
        })}
      </table>
    </>
  );
}

export default Completed;
