import React, { useEffect, useRef, useState } from "react";
import "./BurgurBatan.css";
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import MenuBookIcon from '@mui/icons-material/MenuBook';

export const BurgurButton = (props) => {
  const [current, setCurrent] = useState(0);
  const [currentState, setCurrentState] = useState(false);
  const sideNavRef = useRef(null);
  const navigation = [
    { name: "Home", href: "", icon: <HomeIcon /> },
    { name: "About Us", href: "about",icon: <InfoIcon /> },
    { name: "Our Empolyes", href: "OurEmployes",icon: <HomeIcon /> },
    { name: "Our Strenght", href: "OurStrength",icon: <PrecisionManufacturingIcon /> },
    { name: "Completed Project", href: "Completed",icon: <AssignmentTurnedInIcon /> },
    { name: "On Going Project", href: "OnGoing",icon: <ContentPasteGoIcon /> },
    { name: "Key Person", href: "Keyperson",icon: <ManageAccountsIcon /> },
    { name: "About Skyline", href: "AboutCompany",icon: <MenuBookIcon /> },
    { name: "Contact Us", href: "contact",icon: <ContactMailIcon /> },
  ];

  useEffect(() => {
    if (currentState) {
      document.getElementById("mySidenav").style.width = "38vh";
    } else {
      document.getElementById("mySidenav").style.width = "0";
    }
  }, [currentState]);
  const handleClickOutside = (event) => {
    if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
      closeNav();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  function openNav(e) {
    setCurrentState(e.target.checked);
  }

  const closeNav = () => {
    setCurrentState(false);
  };

  const handleHover = (index) => {
    setCurrent(index);
    setCurrentState(false);
    // closeNav()
  };
  return (
    <>
      <div className="burger-container" ref={sideNavRef}>
        <label className="burger left-4 " for="burger">
          <input
            type="checkbox"
            id="burger"
            checked={currentState}
            onChange={openNav}
          />
          <span style={{ background: "#001f5f" }}></span>
          <span style={{ background: "orange" }}></span>
          <span style={{ background: "#001f5f" }}></span>
        </label>
      </div>

      <div id="mySidenav" className="sidenav" >
        {/* <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>&times;</a> */}
        {navigation.map((item, index) => (
          <>
            <Link
              to={`/${item.href}`}
              style={{
                background: index === current && "grey",
                color: "#001f5f",
              }}
              key={item.name}
              aria-current={item.current ? "page" : undefined}
              onClick={() => handleHover(index)}
            >
              {item.icon}
              {item.name}
            </Link>
          </>
        ))}
      </div>
    </>
  );
};
