import React from 'react'
import './Cards.css'

function Cards() {
    return (
        <>
            <div className='main-card'>

                <div class="cards">
                    
                
                   
                </div>

            </div>


        </>
    )
}

export default Cards